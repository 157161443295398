.logoContent {
  /* height: 80px; */
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text1-1, #ccc);
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
}

.logo:visited,
.logo:link {
  color: var(--color-text1-1, #ccc);
  text-decoration: none;
}

.logo img {
  height: 40px;
  margin-right: 10px;
}

.softwareTitle {
  font-size: 22px;
  font-weight: 800;
  color: var(--color-white, #fff);
}