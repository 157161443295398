.footer {
  line-height: 20px;
  text-align: center;
}

.logo {
  font-weight: bold;
  font-size: 16px;

  img {
    width: 125px;
    filter: grayscale(100%);
    /* 默认灰度 */
    transition: filter 0.3s;
    /* 平滑过渡效果 */
    opacity: 0.2;
    /* 默认透明度为 20% */
    transition: opacity 0.3s;
    /* 平滑过渡效果 */
  }

  img:hover {
    opacity: 1;
    /* 鼠标悬停时透明度为 100% */
    filter: none;
    /* 鼠标悬停时移除灰度滤镜 */
  }
}

.copyright {
  font-size: 13px;
}